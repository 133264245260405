import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Indoor_Cameras/IN-6014_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-6014 HD Manual Lense Adjustment",
  "path": "/Indoor_Cameras/IN-6014_HD/Lense_Adjustment/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-6014 HD Indoor IP Camera Manual Lense Adjustment",
  "image": "./P_SearchThumb_IN-6014HD_Lense_Adjust.png",
  "social": "/images/Search/P_SearchThumb_IN-6014HD_Lense_Adjust.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-6014HD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='IN-6014 HD Manual Lense Adjustment' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IN-6014 HD Indoor IP Camera Quick Installation Guide' image='/images/Search/P_SearchThumb_IN-6014HD_Lense_Adjust.png' twitter='/images/Search/P_SearchThumb_IN-6014HD_Lense_Adjust.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-6014_HD/Objektivjustage/' locationFR='/fr/Indoor_Cameras/IN-6014_HD/Lense_Adjustment/' crumbLabel="Lense" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "adjust-the-focus-of-your-in-6014-hd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adjust-the-focus-of-your-in-6014-hd",
        "aria-label": "adjust the focus of your in 6014 hd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adjust the Focus of your IN-6014 HD`}</h2>
    <p>{`The IN-6014 HD’s lense offers a limited field depth that was adjusted to a field between 1m to 8m. In case the object you want to observe lies outside of this area, you will have to adjust the lense accordingly.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "899px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/894b6e8af4eb5af2d67533f54cd1dcfc/681f1/IN-6014_Lense-Adjustments.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACcUlEQVQoz3WSXUiTURjH3zs/8r6biOhjEIkpBIofIGUXXuhKnDbm1IX7rMHclL2kSVPaJiy9WpIi5kAKBjXcvQZ2sRBNHG3sC1x2NzdhMudkPv94j7yCDB845zmc5zm/8z/PczgASCSTsGo0FFBr4e2QkkalonQmI4To7OwMoonrP/E42QYGsTv4ktxd3aTWanFyckJCjBOmt3Y7nlRW0RdFH1wNjXTzWhV9Wly8ABIRG8VikQGNPE+PyyrwdVBFzrpHdL28HN9WV8+B+eNjmEZHcauiEj+U/TTZ3ILbd+5ifn6e0uk0A4hAwY5zOWhevcaNsnL6pTOAv/8AdbW19Hl5GZl0Glw2mxUOo0fRB5VGjaft7fRucpK8Xi8ODg5KgEL+zMwMZHI5NDottbS10Xu7A36/H6lUClyhUMDExAR+bmzg3/4+4rEYEokErFYrTk9PS4BCCYxGI4K7u4jH4/i7t4etrS04HA6hjuc1HBoagtlsRjAYRCwWg81mg1KpvAQTgYLJ5XKMjY8jFAohGo2C53no9QYW45LJJKRSKdRqNQO53W6mTibrwfb27wtVYofD4TA6OjthMBgwNTXF8i0WC7tEgHNmiwX3JBK2Me2cxvDwMLplMtQ8rIFCobgAigr1ej0kEgn6B/rhcDhhMpnwvKsL1dXVTCXX2/sCzS3NDORyfWANejM2hsamJuh0ukv/T4BKpc/Q2tqKkZERzM7OYmFhgT25vqEeVp4Ht7m5yTY9Hg98Ph/W1taY/zg3h52dnRKFgUAAS0tLWFlZYZ1dX1/Hd5+PMSKRCLij7BFyuRyuMhEk+sPMIfL5/JX5/wE5BaDRnBdpFgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/894b6e8af4eb5af2d67533f54cd1dcfc/e4706/IN-6014_Lense-Adjustments.avif 230w", "/en/static/894b6e8af4eb5af2d67533f54cd1dcfc/d1af7/IN-6014_Lense-Adjustments.avif 460w", "/en/static/894b6e8af4eb5af2d67533f54cd1dcfc/06569/IN-6014_Lense-Adjustments.avif 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/894b6e8af4eb5af2d67533f54cd1dcfc/a0b58/IN-6014_Lense-Adjustments.webp 230w", "/en/static/894b6e8af4eb5af2d67533f54cd1dcfc/bc10c/IN-6014_Lense-Adjustments.webp 460w", "/en/static/894b6e8af4eb5af2d67533f54cd1dcfc/8bcb7/IN-6014_Lense-Adjustments.webp 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/894b6e8af4eb5af2d67533f54cd1dcfc/81c8e/IN-6014_Lense-Adjustments.png 230w", "/en/static/894b6e8af4eb5af2d67533f54cd1dcfc/08a84/IN-6014_Lense-Adjustments.png 460w", "/en/static/894b6e8af4eb5af2d67533f54cd1dcfc/681f1/IN-6014_Lense-Adjustments.png 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/894b6e8af4eb5af2d67533f54cd1dcfc/681f1/IN-6014_Lense-Adjustments.png",
              "alt": "IN-6014 HD Objektivjustage",
              "title": "IN-6014 HD Objektivjustage",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Fig. 1/2 Connect the camera to the power supply and the network cable. Open the web interface and turn the lense until you can see a clear and sharp picture.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      